import { AxiosPromise } from "axios";
import { produce } from "immer";
import {
  __,
  equals,
  includes,
  isEmpty,
  isNotEmpty,
  map,
  propEq,
  reject,
  whereAny,
} from "ramda";

import {
  AddressFieldsWithTitle,
  LvHeaderData,
} from "../pages/offerManagement/lvModal/LvOfferTab";
import { FirmadesNutzers, Nutzer } from "../types/identity";
import {
  LvBase,
  LvConstructionAndAddressData,
  LvRow,
  LvRowDTO,
} from "../types/lvTable";
import { fetchGet, fetchPost } from "./fetch";

const IGNORED_CHARACTERISTICS = ["T_M_ZARGE", "T_M_MAULW"];

type ImportedFile = {
  html: string;
  result: LvRowDTO[];
  fileName?: string;
};

export const importLvFile = (formData: FormData) =>
  fetchPost<ImportedFile>("teckentrup-server/api/cart/quote/custom/_import")()(
    formData,
  );

export const importConstructionAndAddress = (fileName: string, file: File) => {
  if (isNotEmpty(fileName)) {
    return fetchGet<LvConstructionAndAddressData>(
      `teckentrup-server/api/cart/quote/custom/import/addressAndConstruction?fileName=${encodeURIComponent(fileName)}`,
    )()();
  }
  const formData = new FormData();
  formData.append("file", file);
  return fetchPost<LvConstructionAndAddressData>(
    "teckentrup-server/api/cart/quote/custom/import/addressAndConstruction",
  )()(formData);
};

const buildDealer = (company: FirmadesNutzers, user: Nutzer) => ({
  customerID: company.FirmenSAPKundennummer,
  userID: user.Email,
});

const buildAddress = ({
  email,
  street,
  houseNumber,
  city,
  ...rest
}: AddressFieldsWithTitle) => ({
  email,
  street,
  houseNumber,
  city,
  contactSalutation: rest.title,
  contactFirstName: rest.firstname,
  contactLastName: rest.surname,
  telephoneNumber: rest.phone,
  postCode: rest.zip,
});

const rejectInvalidParameters = reject<LvBase>(
  whereAny({
    name: includes(__, IGNORED_CHARACTERISTICS),
    value: (value: string) => isEmpty(value) || equals(value, "0"),
  }),
);

function addDinRIfMissing(parameters: LvRow["configurationParameters"]) {
  const foundDinR = parameters.find(propEq("T_M_DINR", "name"));
  if (foundDinR) {
    return parameters;
  }
  return produce(parameters, (draft) => {
    draft.push({ name: "T_M_DINR", value: "U", recognized: "" });
  });
}

const buildCharacteristics = (parameters: LvRow["configurationParameters"]) => {
  const filteredParameters = rejectInvalidParameters(parameters);
  const parametersWithDinR = addDinRIfMissing(filteredParameters);
  return map(
    ({ name, value }) => ({
      characteristicId: name,
      value,
      format: "UNFORMATTED",
    }),
    parametersWithDinR,
  );
};

const buildConfigurableProducts = (selectedPositions: LvRow[]) =>
  map(
    ({ product, quantity, position, reference, configurationParameters }) => ({
      productTO: {
        articleId: product.value,
        amount: quantity.value,
        lvPosition: position.value,
        referenceNo: reference.value,
      },
      characteristics: buildCharacteristics(configurationParameters),
    }),
    selectedPositions,
  );

type ProductsInProcess = {
  cartOrderNumber: string;
};

export const createProductsInProcess = (
  selectedPositions: LvRow[],
  lvHeaderData: LvHeaderData,
  company: FirmadesNutzers,
  user: Nutzer,
): AxiosPromise<ProductsInProcess> => {
  const { buildProject, referenceNumber, comment, ...addressFields } =
    lvHeaderData;
  const body = {
    headerDataTO: {
      buildProject,
      referenceNumber,
      comment,
      dealer: buildDealer(company, user),
      address: buildAddress(addressFields),
      thirdPartySystemName: "extern_import_lv",
    },
    discreteProducts: [],
    configurableProducts: buildConfigurableProducts(selectedPositions),
  };
  return fetchPost<ProductsInProcess>(
    "teckentrup-server/api/request/_importrequest?validationResponse=true",
  )()(body);
};
