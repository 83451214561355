import classNames from "classnames";
import { not, pathOr } from "ramda";
import { useMemo, MouseEvent } from "react";

import { Loading } from "../../../../components/loading";
import { SERVICE_BASE_URL } from "../../../../context/AppProvider";
import { useApp } from "../../../../hooks/useApp";
import { useCart } from "../../../../hooks/useCart";
import {
  cartBelongsToCurrentUser,
  hasNoRightToExportExcel,
  hasNoRightToOrderCart,
  hasNoRightToPrintDocument,
  hasRightToSeeDbCheck,
  hasRightToSeeOrderButton,
  hasRightToShareCart,
} from "../../../../http/identityHelper";
import { getActiveQuote } from "../../../../service/offerManagementService";
import {
  ViewsState,
  getNonOpenedQuoteByOrderNumber,
  hasArticlesWithNoPrice,
} from "../../../../utilities/cartUtils";
import {
  exportExcelFileEvent,
  track,
} from "../../../../utilities/eventTracking";
import {
  isOrdered,
  isShared,
} from "../../../offerManagement/offerManagementTable/offerManagementTableUtils";
import { CART_MODALS, CartModalsReturn } from "../../hooks/useCartModals";
import { CartViewReturn, cartViews } from "../../hooks/useCartView";
import { t } from "../../localizationUtils";
import { getOrderModal, withRetryOnce } from "./buttonRowUtils";
import { hasNoAllowedShippingMethod } from "./calculation/calculationUtils";
import { cartOrderState } from "./footerUtils";

import "./buttonRow.scss";

type ButtonRowProps = {
  modalActions: CartModalsReturn["modalActions"];
  quoteOrderNumber: string;
  changeCartView: CartViewReturn["changeCartView"];
  isPartialOrder: CartViewReturn["partialOrderActivated"];
  isOrderView: ViewsState["isOrder"];
};

export const ButtonRow = ({
  modalActions,
  quoteOrderNumber,
  changeCartView,
  isPartialOrder,
  isOrderView,
}: ButtonRowProps) => {
  const { language, identityStore } = useApp();
  const { cart, cartActions, originalQuoteId } = useCart();
  const { buyable, dbCheckable, exportable, isPartiallyBuyable } =
    cartOrderState(cart, identityStore, cart.authorities);

  const exportableDisabled = useMemo(
    () =>
      !exportable ||
      hasNoRightToExportExcel(cart.authorities.booleanAuthorities),
    [exportable, cart.authorities.booleanAuthorities],
  );

  const cannotOrderCart = useMemo(() => {
    const isOrderNotBuyable = not(isPartialOrder) && not(buyable);
    const isOrderNotOrderable =
      isOrderNotBuyable ||
      hasNoRightToOrderCart(cart.authorities.booleanAuthorities);
    const isInvalidOrder = isOrderNotOrderable && not(originalQuoteId);
    const isInvalidPartialOrder =
      isOrderView && isPartialOrder && not(isPartiallyBuyable);
    return (
      isInvalidOrder ||
      isShared(cart.headerData?.status) ||
      isInvalidPartialOrder ||
      hasNoAllowedShippingMethod(cart.allowedShippingMethods)
    );
  }, [
    buyable,
    cart,
    isOrderView,
    isPartialOrder,
    isPartiallyBuyable,
    originalQuoteId,
  ]);

  const cannotShareCart = useMemo(
    () =>
      (!buyable ||
        hasNoRightToOrderCart(cart.authorities.booleanAuthorities)) &&
      !originalQuoteId,
    [buyable, originalQuoteId, cart],
  );

  const disableShareButton = useMemo(
    () =>
      cannotShareCart ||
      isPartialOrder ||
      !cart.quote ||
      isOrdered(cart.headerData?.status) ||
      !cartBelongsToCurrentUser(identityStore, cart.headerData),
    [cannotShareCart, cart, identityStore, isPartialOrder],
  );

  const toggleOrderCartModal = () => {
    if (isPartialOrder && !isOrderView) {
      changeCartView("view", cartViews.view.orderView.name);
    } else {
      getActiveQuote().then((res) => {
        const { modalName, modalData } = getOrderModal(
          cart,
          res.data.cart.cartTO,
        );
        modalActions.toggleModal(modalName)(modalData);
      });
    }
  };

  const toggleDbCheckModal = async (quoteId: string) => {
    modalActions.toggleModal(CART_MODALS.CART_DB_CHECK_MODAL)({
      title: t("db_check"),
      content: <Loading />,
    });

    withRetryOnce(() => cartActions.dbCheck(quoteId))
      .then(() => {
        modalActions.setModalData({
          title: t("db_check"),
          content: t("db_success"),
        });
      })
      .catch(() => {
        modalActions.setModalData({
          title: t("db_check"),
          content: t("db_fail"),
        });
      });
  };

  const toggleWarningDBCheck = async () => {
    const userEmail = pathOr("", ["Email"], identityStore.getUser());
    const quote = await getNonOpenedQuoteByOrderNumber(
      quoteOrderNumber,
      userEmail,
    );
    if (!quote) {
      modalActions.toggleModal(CART_MODALS.CART_DB_CHECK_MODAL_WARNING)({
        title: t("db_check_warning_title"),
        content: t("db_check_warning"),
      });
    } else {
      await toggleDbCheckModal(quote.cartTO.id);
    }
  };

  function onExport(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    if (exportableDisabled) {
      return;
    }
    track(exportExcelFileEvent);
    window.location.href = event.currentTarget.href;
  }

  return (
    <div className="shoppingCartButtonContainerBottom">
      {hasRightToSeeOrderButton(cart.authorities.booleanAuthorities) && (
        <button
          className="button is-info is-pulled-right is-orange"
          disabled={cannotOrderCart}
          data-button-id="order_shopping_cart"
          {...(not(cannotOrderCart) && { onClick: toggleOrderCartModal })}
        >
          <div className="is-pulled-left">{t("order_shopping_cart")}</div>
          <div className="is-pulled-right">
            <i className="image-icon warenkorb-voll" aria-hidden="true" />
          </div>
        </button>
      )}
      {hasRightToSeeDbCheck(cart.authorities.booleanAuthorities) && (
        <button
          className="button is-info is-pulled-right is-blue-dark"
          onClick={() => toggleWarningDBCheck()}
          disabled={
            !dbCheckable ||
            !cart.quote ||
            isOrdered(cart.headerData?.status) ||
            isShared(cart.headerData?.status)
          }
          data-button-id="db_check"
        >
          <div className="is-pulled-left">{t("db_check")}</div>
          <div className="is-pulled-right">
            <i className="image-icon calc" aria-hidden="true" />
          </div>
        </button>
      )}
      {hasRightToShareCart(cart.authorities.booleanAuthorities) && (
        <button
          className="button is-info is-pulled-right is-blue-dark"
          onClick={() => modalActions.toggleModal(CART_MODALS.SHARE_CART)()}
          disabled={disableShareButton}
          data-button-id="share_shopping_cart"
        >
          <div className="is-pulled-left">{t("share_shopping_cart")}</div>
          <div className="is-pulled-right">
            <i className="image-icon vorgang-teilen" aria-hidden="true" />
          </div>
        </button>
      )}
      <button
        className="button is-info is-pulled-right is-blue-dark"
        disabled={
          !exportable ||
          hasNoRightToPrintDocument(cart.authorities.booleanAuthorities)
        }
        title={
          hasArticlesWithNoPrice(cart.articles) ? t("price_on_request") : ""
        }
        data-button-id="btn_print_offer"
        {...(exportable && {
          onClick: () => modalActions.toggleModal(CART_MODALS.PRINT_OPTION)(),
        })}
      >
        <div className="is-pulled-left">{t("btn_print_offer")}</div>
        <div className="is-pulled-right">
          <i className="image-icon angebot-drucken" aria-hidden="true" />
        </div>
      </button>
      <a
        className={classNames("button is-info is-pulled-right is-blue-dark")}
        href={`${SERVICE_BASE_URL}/api/cart/export/excel?lang=${language}`}
        data-button-id="excel_export"
        onClick={onExport}
        // @ts-expect-error needed for bulma
        disabled={exportableDisabled}
      >
        <div className="is-pulled-left">{t("excel_export")}</div>
        <div className="is-pulled-right">
          <i className="image-icon excel-export" aria-hidden="true" />
        </div>
      </a>
    </div>
  );
};
