import { useEffect, useMemo } from "react";

import { SERVICE_FACTORY } from "../../context/AppProvider";
import { useApp } from "../../hooks/useApp";
import { useCart } from "../../hooks/useCart";
import { useConfiguration } from "../../hooks/useConfiguration";
import { isCurrentUserAdminOrFromSameCompanyAsCreator } from "../../http/identityHelper";
import { isShared } from "../offerManagement/offerManagementTable/offerManagementTableUtils";
import { ButtonRow } from "./components/footer/buttonRow";
import { Calculation } from "./components/footer/calculation/calculation";
import { ButtonRow as HeaderButtonRow } from "./components/header/buttonRow";
import { CartInformation } from "./components/header/cartInformation";
import { CartView } from "./components/header/cartView";
import { PartialOrderCheckbox } from "./components/header/partialOrderCheckbox";
import { Modals } from "./components/modals/modals";
import { Table } from "./components/table/table";
import { Warnings } from "./components/warnings/warnings";
import { useCartModals } from "./hooks/useCartModals";
import { useCartView } from "./hooks/useCartView";

import "./shoppingCart.scss";

export const ShoppingCart = () => {
  const { identityStore } = useApp();
  const { cart, cartActions } = useCart();
  const { remove } = useConfiguration();
  const offerManagementService = useMemo(
    () => SERVICE_FACTORY.createQuoteService(),
    [],
  );
  const {
    cartView,
    cartViews,
    changeCartView,
    isAlreadyPartiallyOrdered,
    partialOrderActivated,
    viewActions,
  } = useCartView();
  const { modals, modalActions, modalData, modalLoadingStates } = useCartModals(
    offerManagementService,
  );

  useEffect(() => {
    if (!isShared(cart.headerData?.status)) {
      cartActions.getCart().then();
    }
  }, []);

  const hasRights = isCurrentUserAdminOrFromSameCompanyAsCreator(
    identityStore,
    cart.headerData,
    cart.authorities,
  );

  return (
    <div className="shopping-cart" data-page-name="shopping-cart">
      <div className="shopping-cart-head">
        <CartInformation />
        <div className="shopping-cart-view columns">
          <CartView changeCartView={changeCartView} cartView={cartView} />
          <HeaderButtonRow
            saveQuoteAndClose={cartActions.saveQuoteAndClose(
              offerManagementService,
              remove,
            )}
            hasRights={hasRights}
            toggleModal={modalActions.toggleModal}
            toggleModalLoading={modalActions.toggleModalLoading}
            modalLoadingStates={modalLoadingStates}
          />
        </div>
        <Warnings />
      </div>
      {cartViews.isOrder && (
        <PartialOrderCheckbox
          partialOrder={partialOrderActivated}
          isPartiallyOrdered={isAlreadyPartiallyOrdered}
          togglePartialOrder={viewActions.togglePartialOrder}
          isReadOnly={cart.readOnly}
        />
      )}
      <Table
        cartViews={cartViews}
        hasRights={hasRights}
        modalActions={modalActions}
        modalLoadingStates={modalLoadingStates}
        partialOrderActivated={partialOrderActivated}
        viewActions={viewActions}
      />
      <Calculation cartViews={cartViews} />
      <ButtonRow
        modalActions={modalActions}
        quoteOrderNumber={cart.orderNumber}
        changeCartView={changeCartView}
        isPartialOrder={partialOrderActivated}
        isOrderView={cartViews.isOrder}
      />
      <Modals
        modals={modals}
        modalActions={modalActions}
        modalData={modalData}
        isPartialOrder={partialOrderActivated}
      />
    </div>
  );
};
