import {
  curry,
  drop,
  equals,
  filter,
  isEmpty,
  length,
  map,
  not,
  pathOr,
  pick,
  reject,
  take,
} from "ramda";
import { useRef, useState } from "react";
import { useImmer } from "use-immer";

import { importConstructionAndAddress } from "../service/lvService";
import {
  LvConstructionAndAddressData,
  LvEmptyRow,
  LvRow,
  LvRowDTO,
} from "../types/lvTable";
import {
  areAllValuesEmpty,
  convertUtf8Literals,
  isLvEmptyRow,
  isLvRow,
  parseQuantity,
  prepareParameters,
  prepareText,
  splitParameters,
} from "../utilities/lvUtils";
import { useLvConfiguration } from "./useLvConfiguration";

// Lower number doesn't work, because it results in duplicated positions (e.g. with 3 it duplicates 4, 5 and 6)
const PRODUCTS_PAGE_SIZE = 15;

export const useLvTable = () => {
  const { initializeLvParameters } = useLvConfiguration();
  const lvHtmlRef = useRef("");
  const lvRowDTOsRef = useRef<LvRowDTO[]>([]);
  const lvFileNameRef = useRef("");
  const lvFileRef = useRef<File | null>(null);
  const lvOldFileRef = useRef<File | null>(null);
  const [lvRows, setLvRows] = useImmer<Array<LvRow | LvEmptyRow>>([]);
  const [areAllLvRowsChecked, setAreAllLvRowsChecked] =
    useState<boolean>(false);
  const [constructionAndAddressData, setConstructionAndAddressData] =
    useState<LvConstructionAndAddressData>({
      addresses: [],
      construction: [],
      reference: [],
      contactPersons: [],
    });

  const lvRowDTOsLength = length(lvRowDTOsRef.current);

  const checkedLvRows = filter(
    pathOr(false, ["checked"]),
    reject(isLvEmptyRow, lvRows),
  );

  const isAnyLvRowChecked = not(isEmpty(checkedLvRows));

  const areAllLvRowsLoaded = equals(lvRowDTOsLength, length(lvRows));

  const areLvFilesEqual = equals(lvFileRef.current, lvOldFileRef.current);

  async function prepareLvRows(
    lv: LvRowDTO[],
    pageSize: number = PRODUCTS_PAGE_SIZE,
  ): Promise<Array<LvRow | LvEmptyRow>> {
    const pagedLvRowDTOs = take<LvRowDTO>(pageSize, drop(length(lvRows), lv));
    return await Promise.all(
      map(async ({ quantity, parameters, ...rest }) => {
        if (areAllValuesEmpty(parameters)) {
          const preparedText = prepareText(rest.position.value, rest.text);
          return {
            ...pick(["position", "detail"], rest),
            text: preparedText,
          };
        }
        const preparedParameters = prepareParameters(parameters);
        const { lvParameters, configurationParameters } =
          splitParameters(preparedParameters);
        const guiParameters = await initializeLvParameters(lvParameters);
        return {
          checked: false,
          quantity: parseQuantity(quantity),
          product: guiParameters.product,
          parameters: guiParameters.parameters,
          configurationParameters,
          ...rest,
        };
      }, pagedLvRowDTOs),
    );
  }

  async function initializeLvRows(
    lvHtml: string,
    lv: LvRowDTO[],
    selectedFile: File,
    fileName: string = "",
  ) {
    lvHtmlRef.current = convertUtf8Literals(lvHtml);
    lvRowDTOsRef.current = lv;
    lvFileRef.current = selectedFile;
    lvFileNameRef.current = fileName;
    const preparedLvRows = await prepareLvRows(lv);
    setLvRows(preparedLvRows);
  }

  function setFileToOldFile() {
    lvOldFileRef.current = lvFileRef.current;
  }

  async function getAddressAndConstruction() {
    const { data } = await importConstructionAndAddress(
      lvFileNameRef.current,
      lvFileRef.current!,
    );
    setConstructionAndAddressData(data);
    setFileToOldFile();
  }

  async function getUnloadedLvRows() {
    const preparedLvRows = await prepareLvRows(lvRowDTOsRef.current);
    setLvRows((draft) => draft.concat(preparedLvRows));
  }

  async function getAllUnloadedLvRows() {
    if (areAllLvRowsLoaded) {
      return [];
    }
    return await prepareLvRows(
      lvRowDTOsRef.current,
      lvRowDTOsLength - length(lvRows),
    );
  }

  function toggleLvRowChecked(rowIndex: number) {
    setLvRows((draft) => {
      const lvRow = draft[rowIndex];
      if (isLvRow(lvRow)) {
        lvRow.checked = not(lvRow.checked);
      }
    });
  }

  async function toggleAllLvRowsChecked() {
    const allUnloadedLvRows = await getAllUnloadedLvRows();
    setLvRows((draft) =>
      map((lvRow) => {
        if (isLvEmptyRow(lvRow) || isEmpty(lvRow.product.value)) {
          return lvRow;
        }
        return { ...lvRow, checked: not(areAllLvRowsChecked) };
      }, draft.concat(allUnloadedLvRows)),
    );
    setAreAllLvRowsChecked(not(areAllLvRowsChecked));
  }

  const updateLvRowValue = curry(
    (
      rowIndex: number,
      parameterKey: keyof Pick<LvRow, "position" | "reference" | "quantity">,
      value: string | number,
    ) => {
      setLvRows((draft) => {
        const lvRow = draft[rowIndex];
        if (isLvRow(lvRow)) {
          lvRow[parameterKey].value = value;
        }
      });
    },
  );

  function updateLvRowProduct(rowIndex: number, product: LvRow["product"]) {
    setLvRows((draft) => {
      const lvRow = draft[rowIndex];
      if (isLvRow(lvRow)) {
        lvRow.product = product;
      }
    });
  }

  function updateLvRowParameters(
    rowIndex: number,
    parameters: LvRow["parameters"],
  ) {
    setLvRows((draft) => {
      const lvRow = draft[rowIndex];
      if (isLvRow(lvRow)) {
        lvRow.parameters = parameters;
      }
    });
  }

  function clearLvTableState() {
    lvHtmlRef.current = "";
    lvRowDTOsRef.current = [];
    lvFileNameRef.current = "";
    lvOldFileRef.current = null;
    setLvRows([]);
    setAreAllLvRowsChecked(false);
  }

  return {
    lvHtml: lvHtmlRef.current,
    lvRows,
    areAllLvRowsChecked,
    lvRowDTOsLength,
    checkedLvRows,
    isAnyLvRowChecked,
    areAllLvRowsLoaded,
    initializeLvRows,
    getUnloadedLvRows,
    toggleLvRowChecked,
    toggleAllLvRowsChecked,
    updateLvRowValue,
    updateLvRowProduct,
    updateLvRowParameters,
    clearLvTableState,
    constructionAndAddressData,
    areLvFilesEqual,
    lvOldFile: lvOldFileRef.current,
    getAddressAndConstruction,
    setFileToOldFile,
  };
};

export type LvTable = ReturnType<typeof useLvTable>;
