import { AxiosPromise } from "axios";
import Bacon from "baconjs";
import { curry, isNil } from "ramda";
import { createContext, PropsWithChildren, useEffect, useState } from "react";

import { useApp } from "../hooks/useApp";
import { prepareInformationDTO } from "../pages/offerManagement/modifyOfferModal/modifyOfferModalActions";
import { CONFIGURATION_COMPLETE } from "../pages/shoppingCart/components/footer/footerUtils";
import { NewCheckoutInformation } from "../pages/shoppingCart/components/modals/orderModal/orderHelper";
import { t } from "../pages/shoppingCart/localizationUtils";
import {
  performCheckoutPartOrder,
  performCheckoutSharedQuote,
  performDbCheck,
  duplicateArticle as serviceDuplicateArticle,
} from "../service/cartRestService";
import {
  getActiveQuote,
  getSharedQuote,
  loadSharedQuote,
} from "../service/offerManagementService";
import {
  CartActions,
  CartResultTO,
  ExtendedCartResultTO,
  ExtendedTeckentrupCartTO,
  PreparedFreeArticleTO,
  TeckentrupCartArticleTO,
} from "../types/cart";
import {
  ORDER_STATUS_FLAGGED,
  ORDER_STATUS_UNORDERD,
} from "../utilities/cartUtils";
import {
  createGroupEvent,
  orderRequestSentEvent,
  track,
  trackFunction,
} from "../utilities/eventTracking";
import { getPartOrderDetails } from "../utilities/rowUtils";
import { SERVICE_FACTORY } from "./AppProvider";
import { getResponseAuthorities, getResponseCart } from "./cartProviderUtils";

type ResolveFunction = () =>
  | Promise<CartResultTO>
  | AxiosPromise<ExtendedCartResultTO>;

type CartContextType = {
  loading: boolean;
  properties: Map<string, string>;
  originalQuoteId: string;
  cart: ExtendedTeckentrupCartTO;
  cartActions: CartActions;
};

export const CartContext = createContext<CartContextType | null>(null);

export function CartProvider({ children }: Readonly<PropsWithChildren>) {
  const { language } = useApp();
  const [cart, setCart] = useState<ExtendedTeckentrupCartTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState(new Map<string, string>());

  const cartService = SERVICE_FACTORY.createCartService("UI");

  const resolveCart = async (fn: ResolveFunction) => {
    const response = await fn();
    const responseCart = getResponseCart(response);
    setCart((prev) => ({
      ...responseCart,
      loading: false,
      authorities: getResponseAuthorities(response, prev),
      articles: {
        ...responseCart.articles,
        subArticles: getPartOrderDetails(responseCart),
      },
    }));
    return responseCart;
  };

  const getCart = async () => resolveCart(getActiveQuote);

  useEffect(() => {
    cartService.touch("NOTHING");
    Bacon.interval(120000).onValue(() => cartService.touch("NOTHING"));
    getCart().then();
  }, []);

  if (isNil(cart)) {
    return null;
  }

  const resolveCartWithLoading = async (fn: ResolveFunction) => {
    setLoading(true);
    const cartResult = await resolveCart(fn);
    setLoading(false);
    return cartResult;
  };

  const setOptional = curry(
    ({ articleId }: { articleId: string }, optional: boolean) =>
      resolveCart(() => cartService.setOptional(articleId, optional)),
  );

  const setMargin = curry(
    ({ articleId }: { articleId: string }, value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(articleId, "setMargin", {
          margin: value,
          absolute: true,
        }),
      ),
  );

  const setAmount = curry(
    ({ articleId }: { articleId: string }, value: number) =>
      resolveCart(() => cartService.setAmount(articleId, value)),
  );

  const setMarginPerc = curry(
    ({ articleId }: { articleId: string }, value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(articleId, "setMargin", {
          margin: value,
          absolute: false,
        }),
      ),
  );

  const setComment = async (
    { articleId }: { articleId: string },
    value: string,
  ) =>
    resolveCart(() =>
      cartService.performArticleOperation(articleId, "setComment", {
        comment: value,
      }),
    );

  const setProperty = async (
    { articleId }: { articleId: string },
    property: string,
    value: string,
  ) =>
    resolveCart(() =>
      cartService.performArticleOperation(articleId, "setProperty", {
        key: property,
        [property]: value,
      }),
    );

  const setPropertyConfiguration = async (property: string, value: string) => {
    const map = properties;
    map.set(property, value);
    setProperties(map);
  };

  const setPropertiesOnSave = async (articleId: string) => {
    const keys = properties.keys();
    let key = keys.next();
    while (!key.done) {
      const prop = properties.get(key.value);
      if (!isNil(prop)) {
        await setProperty({ articleId: articleId }, key.value, prop);
        key = keys.next();
      }
    }
    setProperties(new Map());
  };

  const setDiscountPerc = curry(
    ({ articleId }: { articleId: string }, value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(
          articleId,
          "encoway.cart_service.SetArticleDiscount",
          {
            discount: value,
          },
        ),
      ),
  );

  const setPositionDiscountPerc = curry(
    ({ articleId }: { articleId: string }, value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(articleId, "setPositionDiscount", {
          discountRate: value,
          absolute: false,
        }),
      ),
  );

  const setPositionDiscount = curry(
    ({ articleId }: { articleId: string }, value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(articleId, "setPositionDiscount", {
          discountRate: value,
          absolute: true,
        }),
      ),
  );

  const setCampaignDiscountPerc = curry(
    ({ articleId }: { articleId: string }, value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(articleId, "setCampaignDiscount", {
          discount: value,
        }),
      ),
  );

  const setFinalSalesPrice = (value: number) =>
    resolveCart(() =>
      cartService.performOperation("setOveralDiscount", {
        absolute: true,
        discount: value,
      }),
    );

  const setFinalDiscount = (value: number) =>
    resolveCart(() =>
      cartService.performOperation("setOveralDiscount", {
        absolute: false,
        discount: value,
      }),
    );

  const setCalculationBasis = (calculationBasis: string | null) =>
    resolveCart(() =>
      cartService.performOperation("setCalculationBasis", {
        calculationBasis: calculationBasis,
      }),
    );

  const setGroupName = ({ articleId }: { articleId: string }, value: string) =>
    resolveCart(() => cartService.renameGroup(articleId, value));

  const setPriceListDiscount = curry(
    ({ articleId }: { articleId: string }, value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(
          articleId,
          "setDiscountOnPriceList",
          {
            discountOnPriceList: value,
            absolute: true,
          },
        ),
      ),
  );

  const setPriceListDiscountPerc = curry(
    ({ articleId }: { articleId: string }, value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(
          articleId,
          "setDiscountOnPriceList",
          {
            discountOnPriceList: value,
            absolute: false,
          },
        ),
      ),
  );

  const saveCheckoutInformation = async (checkoutInformation: unknown) =>
    resolveCart(() => cartService.setHeaderInformation(checkoutInformation));

  const performCheckout = async () => {
    await cartService.performCheckout("ALL");
    await getCart();
    track(orderRequestSentEvent);
  };

  const performSharedQuoteCheckout = async (
    checkoutInformation: NewCheckoutInformation,
  ) => {
    await performCheckoutSharedQuote(cart.properties.originalQuoteId, {
      ...cart.headerData,
      ...checkoutInformation,
    });
    await getCart();
  };

  const performPartOrderCheckout = async (
    checkoutInformation: NewCheckoutInformation,
  ) => {
    await performCheckoutPartOrder({
      ...cart.headerData,
      ...checkoutInformation,
    });
    await cartService.performOperation("recalculation", {});
    await getCart();
  };

  const removeCartItem = (article: TeckentrupCartArticleTO) =>
    resolveCart(() =>
      article.group
        ? cartService.removeGroup(article.articleId)
        : cartService.removeArticle(article.articleId),
    );

  const saveConfiguration = (configurationId: string) =>
    resolveCartWithLoading(() =>
      cartService.addConfiguredArticle(configurationId),
    );

  const saveQuoteAndClose =
    (offerManagementService: any, remove: () => Promise<void>) => async () => {
      await offerManagementService.saveQuoteAndClose();
      await remove();
      return getCart();
    };

  const saveQuote = async (
    offerManagementService: any,
    checkoutInformation: any,
  ) => {
    await saveCheckoutInformation(prepareInformationDTO(checkoutInformation));
    await offerManagementService.saveQuote();
    return getCart();
  };

  const setShippingMethod = curry(
    (orderView: boolean, shippingMethodId: string) =>
      resolveCart(() =>
        cartService.performOperation("setShippingMethod", {
          shippingMethod: shippingMethodId,
          orderView,
        }),
      ),
  );

  const setShippingPrice = (shippingPrice: number, orderView: boolean) =>
    resolveCart(() =>
      cartService.performOperation("setShippingPrice", {
        shippingPrice,
        orderView,
      }),
    );

  const setTaxRate = (taxRate: number) => {
    resolveCart(() => cartService.performOperation("setTaxRate", { taxRate }));
  };

  const update = (
    articleId: string,
    configurationId: string,
    articleOrderStatus?: string,
    readyState?: string,
  ) => {
    const orderStatusFlagged = articleOrderStatus === ORDER_STATUS_FLAGGED;
    const configurationReady = readyState === CONFIGURATION_COMPLETE;
    return resolveCart(async () => {
      if (orderStatusFlagged && !configurationReady) {
        await cartService.performArticleOperation(
          articleId,
          "setFlagAsPartOrder",
          {
            orderStatus: ORDER_STATUS_UNORDERD,
            isGroup: false,
            allPositions: false,
          },
        );
      }
      return cartService.updateConfiguredArticle(articleId, configurationId);
    });
  };

  const dbCheck = async (quoteId: string) => performDbCheck(quoteId);

  const add = (productId: string, amount = 1) =>
    resolveCartWithLoading(() =>
      cartService.addCatalogArticle(productId, amount),
    );

  const getArticleConfiguration = (articleId: string) =>
    cartService.getArticleConfiguration(articleId);

  const removeAllCartEntries = () => resolveCart(() => cartService.resetCart());

  const updateFreePosition = (
    position: PreparedFreeArticleTO,
    articleId: string,
  ) =>
    resolveCartWithLoading(() =>
      cartService.updateFreeArticle(articleId, position),
    );

  const overwriteState = (cartObj: Partial<ExtendedTeckentrupCartTO>) =>
    setCart((prev) => {
      if (isNil(prev)) {
        return cartObj as ExtendedTeckentrupCartTO;
      }
      return { ...prev, ...cartObj };
    });

  const createGroup = trackFunction(
    () =>
      resolveCartWithLoading(() =>
        cartService.createGroup(t("cart_new_folder")),
      ),
    createGroupEvent,
  );

  const getSharedCart = (id: string) => resolveCart(() => getSharedQuote(id));

  const openSharedCart = (id: string) => resolveCart(() => loadSharedQuote(id));

  const createFreePosition = (
    position: PreparedFreeArticleTO,
    amount: number,
  ) =>
    resolveCartWithLoading(() => cartService.addFreeArticle(position, amount));

  const moveArticle = (
    dragItemId: string,
    dragEndItemId: string,
    index: number,
  ) =>
    resolveCart(() =>
      cartService.moveArticleToGroup(dragItemId, index, dragEndItemId),
    );

  const duplicateArticle = ({ articleId }: TeckentrupCartArticleTO) =>
    resolveCartWithLoading(() => serviceDuplicateArticle(articleId, language));

  const resetProperties = () => setProperties(new Map());

  const value = {
    loading,
    properties,
    originalQuoteId: cart.properties.originalQuoteId,
    cart,
    cartActions: {
      add,
      update,
      getArticleConfiguration,
      removeAllCartEntries,
      updateFreePosition,
      overwriteState,
      getSharedCart,
      openSharedCart,
      createGroup,
      createFreePosition,
      moveArticle,
      duplicateArticle,
      setPriceListDiscountPerc,
      setPriceListDiscount,
      removeCartItem,
      saveConfiguration,
      performCheckout,
      saveCheckoutInformation,
      setCalculationBasis,
      setFinalDiscount,
      setFinalSalesPrice,
      setCampaignDiscountPerc,
      setPositionDiscount,
      setPositionDiscountPerc,
      setDiscountPerc,
      setComment,
      setPropertyConfiguration,
      setPropertiesOnSave,
      setProperty,
      setMarginPerc,
      setMargin,
      setOptional,
      setAmount,
      setShippingMethod,
      setShippingPrice,
      setGroupName,
      getCart,
      saveQuote,
      saveQuoteAndClose,
      setTaxRate,
      performSharedQuoteCheckout,
      dbCheck,
      resetProperties,
      performPartOrderCheckout,
    },
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}
