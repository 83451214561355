import classNames from "classnames";
import { length, not } from "ramda";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { ModalBodyWrapper, ModalFooter } from "../../../components/modal/modal";
import { LvTable } from "../../../hooks/useLvTable";
import { LV_TAB_ID, LvTabId } from "../../../utilities/lvUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { LvTableComponent } from "../lvTable/LvTableComponent";

import "./lvTableTab.scss";

type LvTableHeaderProps = {
  lvTable: LvTable;
  selectedFile: File | null;
};

const LvTableHeader = ({ lvTable, selectedFile }: LvTableHeaderProps) => {
  return (
    <div className="lv-table-header">
      <div className="is-flex is-justify-content-space-between mb-3 with-padding">
        <div className="is-flex gap-1">
          <p>{t("offer_management_create_lv_import_read_lv")}:</p>
          <p className="has-text-weight-bold">{selectedFile?.name}</p>
        </div>
        <div className="is-flex has-text-success-dark gap-4">
          <div className="is-flex gap-1">
            <p>{t("offer_management_create_lv_positions_read")}:</p>
            <p className="has-text-weight-bold">{lvTable.lvRowDTOsLength}</p>
          </div>
          <div className="is-flex selected-positions gap-1">
            <p>{t("offer_management_create_lv_positions_selected")}:</p>
            <p className="has-text-weight-bold">
              {length(lvTable.checkedLvRows)}
            </p>
          </div>
        </div>
      </div>
      <div className="custom-thead">
        <div className="custom-th">
          <input
            className="checkbox"
            type="checkbox"
            checked={lvTable.areAllLvRowsChecked}
            onChange={lvTable.toggleAllLvRowsChecked}
          />
        </div>
        <div className="custom-th">{t(`offer_management_lv_position`)}</div>
        <div className="custom-th">{t(`offer_management_lv_product`)}</div>
        <div className="custom-th">{t(`offer_management_lv_details`)}</div>
      </div>
    </div>
  );
};

type LvTableTabProps = {
  isHidden: boolean;
  lvTable: LvTable;
  selectedFile: File | null;
  onCancel: () => void;
  setSelectedTab: Dispatch<SetStateAction<LvTabId>>;
};

export const LvTableTab = ({
  isHidden,
  lvTable,
  selectedFile,
  onCancel,
  setSelectedTab,
}: LvTableTabProps) => {
  const modalBodyWrapperRef = useRef<HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // needed because of a scroll bug in Firefox, that doesn't reset the scroll state on unmount
    modalBodyWrapperRef.current?.scrollTo({ top: 0 });
  }, []);

  async function fetchAddressAndConstruction() {
    setIsLoading(true);
    try {
      await lvTable.getAddressAndConstruction();
      setSelectedTab(LV_TAB_ID.LV_HEADER_DATA);
    } catch (error) {
      setSelectedTab(LV_TAB_ID.LV_HEADER_DATA);
      lvTable.setFileToOldFile();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ModalBodyWrapper
        className={classNames("select-positions-tab", {
          "is-hidden": isHidden,
        })}
        ref={modalBodyWrapperRef}
      >
        <LvTableHeader lvTable={lvTable} selectedFile={selectedFile} />
        <InfiniteScroll
          className="infinite-scroll"
          pageStart={0}
          loadMore={lvTable.getUnloadedLvRows}
          hasMore={not(lvTable.areAllLvRowsLoaded)}
          loader={<div className="loader" key={0} />}
          useWindow={false}
        >
          <LvTableComponent lvTable={lvTable} />
        </InfiniteScroll>
      </ModalBodyWrapper>
      <ModalFooter
        className={classNames({ "is-hidden": isHidden })}
        cancelLabel={t("cancel")}
        saveLabel={t("next")}
        saveButtonId="lv_positions_next"
        onCancel={onCancel}
        onSave={() =>
          lvTable.areLvFilesEqual
            ? setSelectedTab(LV_TAB_ID.LV_HEADER_DATA)
            : fetchAddressAndConstruction()
        }
        disabled={!lvTable.isAnyLvRowChecked}
        isLoading={isLoading}
      />
    </>
  );
};
